// This file was automatically generated from concierge.manage.user.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Manage == 'undefined') { Nightloop.Templates.Concierge.Manage = {}; }
if (typeof Nightloop.Templates.Concierge.Manage.User == 'undefined') { Nightloop.Templates.Concierge.Manage.User = {}; }


Nightloop.Templates.Concierge.Manage.User.List = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Manage.User.ListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.User.ListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-access-user-list"><div class="white-box"><div class="white-box-block">');
  if (opt_data.content.can_administrate_users) {
    output.append('<div class="float-right"><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/manage/user/create" class="ajaxify">');
    Nightloop.Templates.Widget.GoldButton({text: '+ ADD USER ACCOUNT', size: 'small', classes: 'add-user-btn'}, output);
    output.append('</a><div class="float-end"></div></div>');
  } else {
    output.append('<div class="float-right supersubtext">Note: your account does not have access to Administrate User Access</div>');
  }
  output.append('<div class="content-header float-left">User Accounts (', soy.$$escapeHtml(opt_data.content.users_group.all.length), ')</div><div class="float-end"></div><table class="user-list-table">');
  Nightloop.Templates.Concierge.Manage.User.UserGroupSection(soy.$$augmentData(opt_data, {title: 'Users', users: opt_data.content.users_group.all, can_administrate_users: opt_data.content.can_administrate_users}), output);
  output.append('</table><div class="subtext"><br/><a href="javascript:void(0);" class="return-to-top-link">(return to top)</a></div></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Manage.User.List.initialize();});<\/script></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.User.UserGroupSection = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  if (opt_data.users.length > 0) {
    output.append('<tr class="user-list-header-row"><td class="name-col">Name</td><td class="job-col">Job Title</td><td class="options-col">Additional Options</td><td class="emailnotif-col">Email Notifications</td></tr>');
    var userList6021 = opt_data.users;
    var userListLen6021 = userList6021.length;
    for (var userIndex6021 = 0; userIndex6021 < userListLen6021; userIndex6021++) {
      var userData6021 = userList6021[userIndex6021];
      output.append('<tr class="user-list-row ', (userIndex6021 % 2 == 0) ? 'even' : 'odd', '"><td class="name-col"><div class="user-name">', (opt_data.can_administrate_users) ? '<a href="' + soy.$$escapeHtml(opt_data.concierge.base_url) + '/manage/user/' + soy.$$escapeHtml(userData6021.id) + '/edit" class="ajaxify">' + soy.$$escapeHtml(userData6021.full_name) + '</a>' : soy.$$escapeHtml(userData6021.full_name), '</div><div class="user-email">', (userData6021.email_address) ? soy.$$escapeHtml(userData6021.email_address) : '-', '</div></td><td class="job-col">', (userData6021.job_title) ? soy.$$escapeHtml(userData6021.job_title) : '-', '</td><td class="options-col">', (userData6021.addl_options_display) ? soy.$$escapeHtml(userData6021.addl_options_display) : '-', '</td><td class="emailnotif-col">', (userData6021.email_notifications_display) ? soy.$$escapeHtml(userData6021.email_notifications_display) : '-', '</td></tr>');
    }
  } else {
    output.append('<tr><td class="user-msg" colspan="4">There are no users.</td></tr>');
  }
  return opt_sb ? '' : output.toString();
};
