// This file was automatically generated from concierge.nightlife.search.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Nightlife == 'undefined') { Nightloop.Templates.Concierge.Nightlife = {}; }


Nightloop.Templates.Concierge.Nightlife.Search = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Nightlife.SearchBit', full_width: true}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.SearchBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="search-container"><div id="left-filters" class="float-left"><div><div id="header">Make a Nightlife Reservation</div><div id="search-box"><form id="search-form" class="ajaxify" method="GET" action="', soy.$$escapeHtml(opt_data.concierge.base_url), '/nightlife/search"><input id="dv-input" type="hidden" name="dv" value="', soy.$$escapeHtml(opt_data.content.dv), '"/><div class="search-input"><input id="search-query-input" type="text" name="search_query" placeholder="Name or keywords (optional)" value="', soy.$$escapeHtml(opt_data.content.search_query), '" /></div><div class="search-input"><select name=\'', soy.$$escapeHtml(opt_data.content.form.fields.municipality.name), '\'>');
  var muniList6142 = opt_data.content.municipalities;
  var muniListLen6142 = muniList6142.length;
  for (var muniIndex6142 = 0; muniIndex6142 < muniListLen6142; muniIndex6142++) {
    var muniData6142 = muniList6142[muniIndex6142];
    output.append('<option ', (opt_data.content.muni_id == muniData6142[0]) ? 'selected' : '', ' value="', soy.$$escapeHtml(muniData6142[0]), '">', soy.$$escapeHtml(muniData6142[1]), ', ', soy.$$escapeHtml(muniData6142[2]), '</option>');
  }
  output.append('</select></div><div id="search-date-select" class="search-input"><div id="search-date-display">', soy.$$escapeHtml(opt_data.content.date_formatted), '</div><input id="search-date-input" type="hidden" class="datepicker sans" name="date-localized" value="', soy.$$escapeHtml(opt_data.content.date_formatted), '"><input id="search-date-input-submit" type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.date.name), '" value="', soy.$$escapeHtml(opt_data.content.date_formatted_default), '"><div class="float-end"></div></div><div class="search-input"><select id="home-partysize-select" name="', soy.$$escapeHtml(opt_data.content.form.fields.max_guests.name), '">');
  var choiceList6166 = opt_data.content.form.fields.max_guests.choices;
  var choiceListLen6166 = choiceList6166.length;
  for (var choiceIndex6166 = 0; choiceIndex6166 < choiceListLen6166; choiceIndex6166++) {
    var choiceData6166 = choiceList6166[choiceIndex6166];
    output.append('<option class="number" value="', soy.$$escapeHtml(choiceData6166[0]), '" ', (choiceData6166[0] == opt_data.content.form.fields.max_guests.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData6166[1]), '</option>');
  }
  output.append('</select></div><div id="search-submit" class="search-button nightlife">SEARCH</div></form></div>');
  Nightloop.Base.NeighborhoodFilter({neighborhoods: opt_data.content.neighborhoods}, output);
  Nightloop.Base.CategoryFilter({venue_class: 'NIGHTLIFE', categories: opt_data.content.categories}, output);
  Nightloop.Base.AtmosphereFilter({atmospheres: opt_data.content.atmospheres}, output);
  output.append('</div></div><div id="search-results" class="float-left"><div id="top-panel"><div class="matches float-left">', soy.$$escapeHtml(opt_data.content.search_numavail), ' MATCH', (opt_data.content.search_numavail > 1 || opt_data.content.search_numavail == 0) ? 'ES' : '', ' FOUND</div><div class="view-mode float-right"><div id="list-view-link" class="float-left active">&nbsp;</div><div id="grid-view-link" class="float-left">&nbsp;</div><div class="float-end"></div></div><div class="float-end"></div></div><div id="results"><div id="overlay-container" class="no-display"><div id="overlay"><!--- <img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/spinner.gif" alt="Loading..." /> ----></div></div>');
  if (! opt_data.content.has_venue_access_in_muni) {
    output.append('<div class="subtext">You currently do not have access to any nightlife in this city.<br/><br/></div>');
  } else {
    output.append('<div id="list-view-results">');
    var resultList6199 = opt_data.content.search_results;
    var resultListLen6199 = resultList6199.length;
    if (resultListLen6199 > 0) {
      for (var resultIndex6199 = 0; resultIndex6199 < resultListLen6199; resultIndex6199++) {
        var resultData6199 = resultList6199[resultIndex6199];
        Nightloop.Templates.Concierge.Nightlife.VenueSearchResultList({MEDIA_URL: opt_data.MEDIA_URL, venue_name: soy.$$escapeHtml(resultData6199.venue.name), venue_neighborhood: soy.$$escapeHtml(resultData6199.venue.neighborhood.name), venue_id: resultData6199.venue.url_key_or_id, neighborhood_id: resultData6199.venue.neighborhood.id, has_perks: resultData6199.venue.has_perks, cutoff_time_display: resultData6199.cutoff_time_display, result: resultData6199, concierge: opt_data.concierge, reward: resultData6199.venue.rewards_display, date_formatted_default: opt_data.content.date_formatted_default, search_query: opt_data.content.search_query}, output);
      }
    } else {
      output.append('<div class="no-results">There are no results matching your search criteria</div>');
    }
    output.append('</div><div id="grid-view-results" class="no-display">');
    var resultList6219 = opt_data.content.search_results;
    var resultListLen6219 = resultList6219.length;
    if (resultListLen6219 > 0) {
      for (var resultIndex6219 = 0; resultIndex6219 < resultListLen6219; resultIndex6219++) {
        var resultData6219 = resultList6219[resultIndex6219];
        Nightloop.Templates.Concierge.Nightlife.VenueSearchResult({MEDIA_URL: opt_data.MEDIA_URL, venue_photos: resultData6219.venue.photos, venue_name: soy.$$escapeHtml(resultData6219.venue.name), venue_neighborhood: soy.$$escapeHtml(resultData6219.venue.neighborhood.name), venue_id: resultData6219.venue.url_key_or_id, neighborhood_id: resultData6219.venue.neighborhood.id, has_perks: resultData6219.venue.has_perks, cutoff_time_display: resultData6219.cutoff_time_display, result: resultData6219, concierge: opt_data.concierge, reward: resultData6219.venue.rewards_display, date_formatted_default: opt_data.content.date_formatted_default, search_query: opt_data.content.search_query}, output);
      }
    } else {
      output.append('<div class="no-results">There are no results matching your search criteria</div>');
    }
    output.append('<div class="float-end"></div></div>');
  }
  output.append('</div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Concierge.Nightlife.Search.initialize( \'', soy.$$escapeHtml(opt_data.concierge.base_url), '\', \'', soy.$$escapeHtml(opt_data.content.muni_today_date), '\', \'', soy.$$escapeHtml(opt_data.content.date_formatted_default), '\',', soy.$$escapeHtml(opt_data.content.search_numavail), ', "', soy.$$escapeHtml(opt_data.concierge.locale), '", "', soy.$$escapeHtml(opt_data.MEDIA_URL), '" );', (opt_data.content.dv == 'g') ? 'Pmp.Concierge.Common.SearchPage._showGridView();' : '', 'Pmp.Concierge.Common.SearchPage._changeDate("', soy.$$escapeHtml(opt_data.concierge.locale), '");});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.FilterItem = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="filter-item"><div class="desc ellipsis float-left"><label class=\'neighborhood-select-label\'><input type=checkbox class="', (opt_data.isAll) ? 'isall' : '', '" ', (opt_data.checked) ? 'checked="checked"' : '', '/><input type=hidden class="filter-item-val', (opt_data.isAll) ? '-all' : '', '" value=\'', soy.$$escapeHtml(opt_data.val), '\' /> ', opt_data.name, '</label></div><div class="count float-left">', (opt_data.count) ? soy.$$escapeHtml(opt_data.count) : '&nbsp;', '</div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.VenueSearchResult = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box float-left" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" category_keywords="', soy.$$escapeHtml(opt_data.result.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.result.venue.atmosphere_keywords_display), '"><div class="photo venue-link">');
  if (opt_data.venue_photos) {
    var photoList6296 = opt_data.venue_photos;
    var photoListLen6296 = photoList6296.length;
    for (var photoIndex6296 = 0; photoIndex6296 < photoListLen6296; photoIndex6296++) {
      var photoData6296 = photoList6296[photoIndex6296];
      output.append((photoIndex6296 == 0) ? '<img src="/.h/download/' + soy.$$escapeHtml(photoData6296.medium) + '" width="339" />' : '');
    }
  } else {
    output.append('&nbsp;');
  }
  output.append('</div><div class="description"><div><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a></div><div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', opt_data.result.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.result.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">' + soy.$$escapeHtml(opt_data.cutoff_time_display) + ' cutoff time</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options"><div><a class="custom-reservation-popup-link custom-request-btn nightlife" href="javascript:void(0);" date=', soy.$$escapeHtml(opt_data.date_formatted_default), ' venue_name="', opt_data.venue_name, '" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '">request</a><div class="float-end"></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Nightlife.VenueSearchResultList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="result-box list-view" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '" neighborhood_id="', soy.$$escapeHtml(opt_data.neighborhood_id), '" category_keywords="', soy.$$escapeHtml(opt_data.result.venue.category_keywords_display), '" atmosphere_keywords="', soy.$$escapeHtml(opt_data.result.venue.atmosphere_keywords_display), '"><div class="description float-left"><div><a href="', soy.$$escapeHtml(opt_data.concierge.base_url), '/venue/', soy.$$escapeHtml(opt_data.venue_id), '?', soy.$$escapeHtml(opt_data.search_query), '" class="venue-name venue-link" target="_blank">', opt_data.venue_name, '</a></div><div class="venue-tags"><div class="ellipsis">', opt_data.venue_neighborhood, ' | ', opt_data.result.venue.category_keywords_display, '</div></div><div class="venue-tags-more ellipsis">', opt_data.result.venue.atmosphere_keywords_display, '</div>', (opt_data.cutoff_time_display) ? '<div class="venue-cutoff">' + soy.$$escapeHtml(opt_data.cutoff_time_display) + ' cutoff time</div>' : '', '<div>', (opt_data.has_perks) ? '<div class="venue-perk float-left">PERK</div>' : '', (opt_data.reward) ? '<div class="venue-rewards float-left">' + soy.$$escapeHtml(opt_data.reward) + '</div>' : '', '<div class="float-end"></div></div></div><div class="booking-options float-right"><div ><a class="custom-reservation-popup-link custom-request-btn nightlife" href="javascript:void(0);" date=', soy.$$escapeHtml(opt_data.date_formatted_default), ' venue_name="', opt_data.venue_name, '" venue_id="', soy.$$escapeHtml(opt_data.venue_id), '">request</a><div class="float-end"></div></div><div class="float-end"></div></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};
